import {
    Box, Button, CheckBox,
    Form,
    FormField, Layer, ResponsiveContext, Select, Tab, Table, TableBody, TableCell, TableHeader, TableRow, Tabs, Text, TextInput
} from 'grommet';
import { Close, Money, Search, StatusGoodSmall, Trash } from 'grommet-icons';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrimaryButton from '../components/elements/button';
import CustomInput from '../components/elements/custom_input';
import Layout from '../components/elements/layout';
import Line from '../components/elements/line_container';
import { all_prestations } from '../components/elements/prestation/data';
import Header from '../components/header';
import salon from '../config/data.json';
import service, { getSalon, hash, payer, searchClients, updateClient } from '../services/service';
import { getSimpleDateTimeFR, toCurrency } from '../services/utils';


const showMessage = (message, type) => {
    toast[type](message);
}

class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            salon,
            logined: false,
            password: '',
            clients: [],
            searchedClients: [],
            timeoutId: '',
            fbs: []
        }
    }

    componentDidMount() {
        if (typeof window !== undefined) {
            let key = window.localStorage.getItem('key');
            if (key === '-536236535') {
                this.setState({ logined: true });
                this.search('');
                this.getSendingReview();
            }
        }
        getSalon()
            .then(res => this.setState({ salon: res.data }));

    }

    handleLogin = () => {
        let password = this.state.password;
        let hashValue = hash(password) || "";
        console.log(hashValue);
        if (hashValue.toString() === '-536236535') {
            window.localStorage.setItem('key', hashValue.toString());
            this.setState({ logined: true });
            this.search('');
            this.getSendingReview();
        }
    }

    search = (query) => {
        searchClients(0, query)
            .then(res => {
                this.setState({ searchedClients: res.data });
            })
    }

    update = client => {
        updateClient(client)
            .then(res => {
                this.search('');
            })
    }

    getSendingReview = () => {
        service.getSendingReview()
            .then(res => {
                this.setState({ fbs: res.data.data });
            })
    }

    render() {
        return (
            <Layout style={{ justifyContent: "center", alignContent: "center" }}>
                <Box width="xlarge" justify="center" align="center" alignSelf="center">
                    <ResponsiveContext.Consumer>
                        {size =>
                            <Box width="xlarge" pad="medium">
                                <Header data={this.state.salon.data} size={size} />
                                {
                                    this.state.logined ?
                                        <Box gap="medium">
                                            <Tabs>
                                                <Tab title="Clients">
                                                    <Clients
                                                        clients={this.state.searchedClients}
                                                        search={this.search}
                                                        update={this.update}
                                                        showMessage={showMessage}
                                                    />
                                                </Tab>
                                                <Tab title="Avis Google">
                                                    <ReviewSending
                                                        fbs={this.state.fbs}
                                                    />
                                                </Tab>
                                            </Tabs>
                                        </Box> :
                                        <Layer full="true">
                                            <Box width="large" gap="medium" pad="medium" justify="center" align="center" alignContent="center" alignSelf="center">
                                                <Box justify="center" gap="medium" align="center">
                                                    <CustomInput
                                                        value={this.state.password}
                                                        onChange={event => this.setState({ password: event.target.value })}
                                                    />
                                                    <PrimaryButton onClick={this.handleLogin} label="Login" />
                                                </Box>
                                            </Box>
                                        </Layer>
                                }
                            </Box>
                        }
                    </ResponsiveContext.Consumer>

                    <ToastContainer position="top-right"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
                    <Box style={{ height: 200 }} />
                </Box>
            </Layout>
        )
    }
}


const ReviewSending = ({ fbs }) => {

    return (
        <Box width="xlarge"
            gap="medium"
        >
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell><Text>Nom</Text></TableCell>
                        <TableCell><Text>Phone</Text></TableCell>
                        <TableCell><Text>Envoyé</Text></TableCell>
                        <TableCell><Text>Visité</Text></TableCell>
                        <TableCell><Text>Satisfait</Text></TableCell>
                        <TableCell><Text>Google review</Text></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        fbs.map((fb, index) => (
                            <TableRow key={index + 1}>
                                <TableCell>
                                    <Text>{fb.name}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>{fb.phone}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>{fb.sent_count === 1 ? 'Oui' : 'Non'}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>{fb.visited_at ? getSimpleDateTimeFR(fb.visited_at) : 'Non'}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text>
                                        {
                                            fb.satisfied === undefined ? '' :
                                                fb.satisfied ? 'Oui' : 'Non'
                                        }
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text>
                                        {
                                            fb.giving_feedback === undefined ? '' :
                                                fb.satisfied ? 'Oui' : 'Non'
                                        }
                                    </Text>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Box>
    )
}


const Clients = ({ clients = [], search, update, showMessage }) => {

    const [query, setQuery] = useState('');
    const [timeoutId, setTimeoutId] = useState('');

    const [selectedClient, setSelectedClient] = useState({});
    const [open, setOpen] = useState(false);

    const localSearch = (query) => {
        setQuery(query);
        query = query || '';
        let searchQuery = query;
        if (searchQuery.startsWith('0')) {
            searchQuery = searchQuery.replace('0', '');
        }
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        let _timeoutId = setTimeout(() => {
            search(searchQuery);
        }, 500);
        setTimeoutId(_timeoutId);

        selectedClient.name = query;
        selectedClient.phone = query;
        setSelectedClient(Object.assign({}, selectedClient));
    }

    const viewClient = client => {
        setSelectedClient(client);
        setOpen(true);
    }

    const localUpdate = client => {
        if (client.id) {
            update(client);
        } else {
            setSelectedClient(Object.assign({}, client));
        }
    }

    return (
        <Box width="xlarge"
            gap="medium"
        >
            <Box style={{ marginTop: 20 }} width="medium">
                <TextInput
                    icon={<Search />}
                    value={query}
                    placeholder="Recherche un client"
                    onChange={event => {
                        localSearch(event.target.value);
                    }}
                />
            </Box>
            {(query && clients.length === 0) &&
                <Box align="center" justify="center">
                    <Close style={{ cursor: 'pointer', right: 0, top: 0 }}
                        onClick={() => {
                            setQuery('');
                            localSearch('');
                            setSelectedClient({});
                        }}
                    />
                    <Box width="medium">
                        <Form>
                            <FormField label="Nom">
                                <TextInput value={selectedClient.name}
                                    onChange={event => {
                                        selectedClient.name = event.target.value;
                                        setSelectedClient(Object.assign({}, selectedClient));
                                    }}
                                />
                            </FormField>
                            <FormField label="Phone">
                                <TextInput value={selectedClient.phone}
                                    onChange={event => {
                                        selectedClient.phone = event.target.value;
                                        setSelectedClient(Object.assign({}, selectedClient));
                                    }}
                                />
                            </FormField>
                            <Box align="center" justify="between" margin={{ top: 'medium' }}>
                                <PrimaryButton
                                    type="submit"
                                    label="Payer"
                                    icon={<Money />}
                                    onClick={() => {
                                        selectedClient.phone = selectedClient.phone || query;
                                        selectedClient.name = selectedClient.name || query;
                                        setSelectedClient(Object.assign({}, selectedClient));
                                        setOpen(true);
                                    }}
                                />
                            </Box>
                        </Form>
                    </Box>
                </Box>
            }
            {(!query || (query && clients.length > 0)) &&
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell><Text>Nom</Text></TableCell>
                            <TableCell><Text>Phone</Text></TableCell>
                            <TableCell><Text>Tampon</Text></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            clients.map((client, index) => (
                                <TableRow key={index + 1}>
                                    <TableCell>
                                        <Text>{client.name}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text>{client.phone}</Text>
                                    </TableCell>
                                    <TableCell>
                                        <Tamponage client={client} update={localUpdate} />
                                    </TableCell>
                                    <TableCell>
                                        <PrimaryButton label="Payer" primary onClick={() => viewClient(client)} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            }
            {open &&
                <ClientPaymentPopup
                    client={selectedClient}
                    onClose={() => {
                        setOpen(false);
                        setSelectedClient({});
                    }}
                    update={localUpdate}
                    showMessage={showMessage}
                />
            }
        </Box>
    )
}

const ClientPaymentPopup = ({ client, onClose, update, showMessage }) => {

    const [googleLinkSending, setGoogleLinkSending] = useState(true);
    const [options, setOptions] = useState(all_prestations);
    const [selectedServices, setSelectedServices] = useState([]);

    const [servicesWithQuantity, setServiceWithQuantity] = useState([]);

    const onRemove = index => {
        servicesWithQuantity.splice(index, 1);
        setServiceWithQuantity(servicesWithQuantity.map(s => s));
        let newServices = selectedServices.filter(s => servicesWithQuantity.map(s => s.id).includes(s.id));
        onSelectedChange(newServices.map(s => s));
    }

    const onSelectedChange = newSelecteds => {
        setSelectedServices(newSelecteds);
        let ids = newSelecteds.map(k => k.id);
        let resting = servicesWithQuantity.filter(s => ids.includes(s.id));
        let newServiceWithQuanity = newSelecteds.filter(s => !resting.map(k => k.id).includes(s.id))
            .map(s => {
                let _s = Object.assign({}, s);
                _s.quantity = 1;
                return _s;
            })
            .concat(resting);
        setServiceWithQuantity(newServiceWithQuanity);
    }

    const onQuantityChange = (service, value) => {
        let selected = servicesWithQuantity.find(s => s.id === service.id);
        selected.quantity = value;
        setServiceWithQuantity(servicesWithQuantity.map(s => s));
    }

    const pay = (amount, reduction, reviewSending, prestations) => {

        let body = {
            client,
            transaction: {
                amount,
                prestations,
                reduction
            },
            reviewSending
        }

        console.log('payment body: ', body);
        payer(body)
            .then(res => {
                showMessage('Payé avec succès!', 'success');
            })
            .catch(err => {
                console.log(err);
                showMessage('Payé échoué', 'error');
            })

    }

    return (

        <Layer onEsc={onClose} position="top" style={{ overflowY: 'scroll' }}>
            <Box width="large" gap="small">
                <Button label=""
                    size="small"
                    style={{ position: 'absolute', right: 0, top: 0 }}
                    icon={<Close color='#e92c70' />}
                    onClick={onClose}
                />
                <Text style={{ paddingTop: 15, fontWeight: 'bold' }} size="large" textAlign="center">{client.name}</Text>
                <Box width="large" gap="small" pad="medium">
                    <Line
                        leftLabel="Phone"
                        rightChildren={
                            <TextInput
                                value={client.phone}
                                onChange={event => client.phone = event.target.value}
                            />
                        }
                    />
                    <Line
                        leftLabel="Tampon"
                        rightChildren={
                            <Tamponage client={client} update={update} />
                        }
                    />
                    <Line
                        leftLabel="Envoie google lien"
                        rightChildren={
                            <CheckBox checked={googleLinkSending} onChange={event => setGoogleLinkSending(event.target.checked)} />
                        }
                    />
                    <Line
                        leftLabel="Prestations"
                        rightChildren={
                            <Select
                                multiple
                                options={options}
                                labelKey="description"
                                valueKey="description"
                                value={selectedServices}
                                dropHeight="medium"
                                onClose={() => setOptions(all_prestations)}
                                onChange={({ value: nextValue }) => onSelectedChange(nextValue)}
                                onSearch={text => {
                                    const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                    const exp = new RegExp(escapedText, 'i');
                                    setOptions(all_prestations.filter(s => exp.test(s.description.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
                                }}
                            />
                        }
                    />
                    {selectedServices.length > 0 &&
                        <Services
                            services={servicesWithQuantity}
                            onRemove={onRemove}
                            onQuantityChange={onQuantityChange}
                            pay={pay}
                            reviewSending={googleLinkSending}
                            tampon={client.tampon}
                        />
                    }
                </Box>
            </Box>
        </Layer>
    )
}

const initReduction = (services, tampon) => {
    if (tampon % 10 === 9) {
        return 20;
    }
    return 0;
}


const Services = ({ services, onRemove, onQuantityChange, pay, reviewSending, tampon }) => {

    const [reduction, setReduction] = useState(initReduction(services, tampon));

    const getTotalPrice = () => {
        let subTotal = services.map(s => parseFloat(s.price.replace(' €', '').replace(',', '.')) * parseInt(s.quantity))
            .reduce((a, s) => a + s, 0);
        return subTotal * (1 - reduction / 100);
    }

    const getRawPrice = () => {
        return services.map(s => parseFloat(s.price.replace(' €', '').replace(',', '.')) * parseInt(s.quantity))
            .reduce((a, s) => a + s, 0);
    }
    return (
        <Box style={{ border: '1px solid #c8c8c8', borderRadius: 5 }} pad="small" gap="small">

            <Box gap="small" direction='row' justify="between" style={{ paddingLeft: 15, paddingRight: 15 }}
                align="center" alignContent='center'
            >
                <Box style={{ width: '70%' }}>
                    <Text style={{ fontWeight: 'bold' }} size="medium">Prestation</Text>
                </Box>
                <Box style={{ width: '30%' }} direction='row' gap='small' justify='end' align="center" alignContent='center'>
                    <Text style={{ fontWeight: 'bold' }} size="medium">Prix </Text>
                    <Text style={{ fontWeight: 'bold' }} size="medium">Quantité </Text>
                </Box>
            </Box>

            {
                services.map((s, index) => (
                    <ServicePrice
                        key={index}
                        service={s}
                        onRemove={() => onRemove(index)}
                        onQuantityChange={onQuantityChange}
                    />
                ))
            }
            <Box style={{ borderBottom: '1px dashed grey' }} />
            <Box gap="small" direction='row' justify="between" style={{ paddingLeft: 15, paddingRight: 15 }}
                align="center" alignContent='center'
            >
                <Box style={{ width: '70%' }}>
                    <Text style={{ fontWeight: 'bold' }} size="medium">Sub total</Text>
                </Box>
                <Box style={{ width: '30%' }} direction='row' gap='small' justify='end' align="center" alignContent='center'>
                    <Text style={{ fontWeight: 'bold' }} size="medium">{toCurrency(getRawPrice())} </Text>
                </Box>
            </Box>

            <Box gap="small" direction='row' justify="between" style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10 }}
                align="center" alignContent='center'
            >
                <Box style={{ width: '50%' }}>
                    <Text style={{ fontWeight: 'bold', color: "#E92C70" }} >Réduction</Text>
                </Box>
                <Box style={{ width: '50%' }} direction='row' gap='xsmall' justify='end' align="center" alignContent='center'>
                    <Box direction='row' justify='end' align="center" alignContent='center'>
                        <Box style={{ width: 70 }}>
                            <TextInput value={reduction} style={{ fontWeight: 'bold', color: "#E92C70" }} onChange={event => setReduction(event.target.value)} />
                        </Box>
                        <Text style={{ fontWeight: 'bold' }} size="medium">% {'=>'}</Text>
                    </Box>
                    <Text style={{ fontWeight: 'bold', color: "#E92C70" }} size="medium">( -{toCurrency(getRawPrice() * reduction / 100)} )</Text>
                </Box>
            </Box>

            <Box style={{ borderBottom: '2px dashed grey' }} />

            <Box gap="small" direction='row' justify="between" style={{ paddingLeft: 15, paddingRight: 15 }}
                align="center" alignContent='center'
            >
                <Box style={{ width: '70%' }}>
                    <Text style={{ fontWeight: 'bold' }} >Total à payer</Text>
                </Box>
                <Box style={{ width: '30%' }} direction='row' gap='small' justify='end' align="center" alignContent='center'>
                    <Text style={{ fontWeight: 'bold' }} size="large">{toCurrency(getTotalPrice())} </Text>
                </Box>
            </Box>

            <Box justify="center" style={{ paddingTop: 15 }} >
                <PrimaryButton
                    label="Payer"
                    size="medium"
                    icon={<Money />}
                    disabled={services.length === 0}
                    onClick={() => pay(getTotalPrice(), reduction, reviewSending, services)}
                />
            </Box>
        </Box>
    )
}

const ServicePrice = ({ service, onRemove, onQuantityChange }) => (
    <Box gap="small" direction='row' justify="between" style={{ paddingLeft: 15, paddingRight: 15 }}
        align="center" alignContent='center'
    >
        <Box style={{ width: '70%' }}>
            <Text>{service.name}</Text>
        </Box>
        <Box style={{ width: '30%' }} direction='row' gap='small' justify='end' align="center" alignContent='center'>
            <Text style={{ fontWeight: 'bold' }} size="medium">{service.price} </Text>
            <Box style={{ width: 50 }} >
                <TextInput type="number" value={service.quantity} min={1} onChange={event => onQuantityChange(service, event.target.value)} />
            </Box>
            <Trash size="small" onClick={onRemove} style={{ cursor: 'pointer' }} />
        </Box>
    </Box>
)

const Tamponage = ({ client, update }) => {

    let useTampon = (client.tampon || 0) % 10;

    const updateTampon = value => {
        client.tampon = value;
        update(client);
    }
    return (
        <Box gap="xsmall" pad="xsmall">
            <Box direction="row" gap="xsmall" align="center">
                {Array.from(Array(5).keys()).map((value) => (
                    <Box gap="xsmall" key={value}>
                        <StatusGoodSmall
                            color={useTampon <= value ? "#fff" : "#33BC00"}
                            style={{ border: '1px solid #C8C8C8', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => updateTampon(value + 1)}
                        />
                    </Box>
                ))}
            </Box>
            <Box direction="row" gap="xsmall" align="center">
                {Array.from(Array(5).keys()).map((value) => (
                    <Box gap="xsmall" key={value + 5}>
                        <StatusGoodSmall
                            color={useTampon <= value + 5 ? "#fff" : "#33BC00"}
                            style={{ border: '1px solid #C8C8C8', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => updateTampon(value + 6)}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Admin;