import { Box, Text } from 'grommet';
import React from 'react';

const CustomText = ({ label }) => <Text style={{ fontWeight: 'bold' }} size="small" textAlign="end" >{label}</Text>
const Line = ({ leftLabel, rightChildren }) => (
    <Box direction='row' gap="medium" style={{ width: '100%' }} align="center" alignSelf="center">
        <Box style={{ width: '30%' }} >
            {leftLabel && <CustomText label={leftLabel + " :"} />}
        </Box>
        <Box style={{ width: '60%' }} >
            {rightChildren}
        </Box>
    </Box>
)

export default Line;