import { TextInput } from 'grommet';
import React from 'react';

const inputStyle = { background: '#EDEDED', fontSize: 16, borderRadius: 10, border: 'none', };

const CustomInput = ({value, onChange, placeholder, disabled}) => {

    return (
        <TextInput 
            style={{width: '100%'}}
            placeholder={placeholder}
            style={inputStyle}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    )
}

export default CustomInput;