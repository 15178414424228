const moment = require('moment');
const getSimpleDateFR = date => {
    if(!date) return date;
    if(typeof date == 'string' && date.includes('/')) {
        return date;
    } 
    return moment(date).format('DD/MM/YYYY');
}
const getSimpleDateTimeFR = date => {
    if(!date) return date;
    if(typeof date == 'string' && date.includes('/')) {
        return date;
    } 
    return moment(date).format('DD/MM/YYYY HH:mm');
}

const toCurrency = val => new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(val);

module.exports = {
    getSimpleDateFR,
    getSimpleDateTimeFR,
    toCurrency,
}